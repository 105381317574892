.Recipe {
    position: fixed;
    width: 50%;
    height: 75%;
    opacity: 100%;
    top: 10%;
}

.Recipe-Notebook {
}

.Recipe-Pastel {
	border-style: solid;
    width: 75%;
    margin: auto;
	box-shadow: 0 0px 100px 0 rgba(1,1,1,1), 0 0px 100px 0 rgba(1,1,1,1);
	background-color: white;
}
