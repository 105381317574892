.ImageUpload {
}

.ImageUpload-Notebook {
}

.ImageUpload-Pastel {
	border-style: solid;
    border-width: 2px;
    width: 100%;
    margin: auto;
    height: fit-content;
    padding-top: 50px;
    padding-bottom: 50px;
}
