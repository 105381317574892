.RecipeDescription {
	width: 100%;
}

.RecipeDescription-Notebook {
	border-bottom-width: 1px;
}

.RecipeDescription-Pastel {
	font-size: 18px;
	line-height: 20px;
	opacity: var(--pastel-opacity);
}
