.ThemeSelector {
	border-right: none;
	border-top: none;
	border-bottom: none;
	background-color: white;
	width: fit-content;
}

.ThemeSelector-Notebook {
	font-family: var(--notebook-font);
	font-size: 20px;
	border-left: none;
}

.ThemeSelector-Pastel {
	font-family: var(--pastel-font);
	font-size: 15px;
	color: var(--pastel-color);
	border-left: block;
	border-left-color: var(--pastel-color);
}

.ThemeSelector-Pastel:hover {
	box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}
