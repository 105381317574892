.RecipeFormRow {
	margin: auto;
	padding-top: 10px;
	padding-bottom: 10px;
	width: fit-content;
}

.RecipeFormRow-Notebook {
	border-bottom-style: dotted;
	border-bottom-color: var(--notebook-color);
	border-bottom-width: 1px;
}

.RecipeFormRow-Pastel {
	width: 90%;
}
