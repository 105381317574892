.Button-Notebook {
	font-family: var(--notebook-font);
	font-size: 25px;
}

.Button-Pastel {
	font-family: var(--pastel-font);
	border-color: var(--pastel-color);
	background-color: white;
	color: var(--pastel-color);
	padding: 10px;
	font-size: 15px;
}

.Button-Pastel:hover {
    color: var(--pastel-highlight-color);
	box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}