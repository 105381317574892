.App {
	text-align: center;
	margin: 0;
	height: 100%;
}

.App-Notebook {
	font-family: var(--notebook-font);
	color: var(--notebook-color);
}

.App-Pastel {
	font-family: var(--pastel-font);
	color: var(--pastel-color);
	background-color: var(--pastel-background-color);
	opacity: var(--pastel-opacity);
}
