.ActiveTag {
    display: flex;
    width: fit-content;
}

.ActiveTag-Notebook {
}

.ActiveTag-Pastel {
    margin: 2px;
    background-color: var(--pastel-color);
    color: white;
}