.RecipePreview {
	margin: auto;
	width: 25%;
	margin-bottom: 5%;
}

.RecipePreview-Notebook {
}

.RecipePreview-Pastel {
    border: solid;
}

.RecipePreview-Pastel:hover {
    color: var(--pastel-highlight-color);
	box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}